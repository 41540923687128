import { StyledFooter } from './Footer.styles';

import logo from '../../images/logo-black.png';

const Footer = () => {
  return (
    <StyledFooter>
      <img src={logo} alt="logo" />
      <p>&copy; {new Date().getFullYear()} Piotr Szewczyk All Rights Reserved</p>
    </StyledFooter>
  );
}

export default Footer;
