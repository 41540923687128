import { Section, AboutMe, PictureBox } from './About.styles';
import { SectionHeader } from '../SectionHeader/SectionHeader'

// import user from '../../images/user-placeholder.jpg';

const About = () => {
  return (
    <Section id="about">
      <AboutMe>
        <SectionHeader text={'Über mich'} marginBottom={'6rem'} />
        <div>
          <p>
            Mein Name ist Piotr Szewczyk und als Flisenlegermeister habe ich mir im Elbtal einen Namen als ehrlicher, kompetenter und zuverlässiger Partner bei allen Projekten rund um Wandfliesen, Bodenfliesen und Trockenbau gemacht. Ich zeige meinen Kunden stets eine große Anzahl regelmäßig wechselnder Beispiele aktueller Fliesenkollektionen der bekanntesten Hersteller und helfe ihnen bei der Auswahl der besten Variante.
          </p>

          <p>
            Lassen Sie mich Ihnen zeigen, welche Möglichkeiten Sie haben und ich baue Ihr Badezimmer von A bis Z um. Ich freue mich auf Ihren Anruf.

            Ich verlege Fliesen im Innen- und Außenbereich, zum Beispiel im Bad, in der Küche, auf Terrassen oder Balkonen und erledige zuverlässig alle Arbeiten wie Fugen, Estriche oder Gipskartonplatten für die Umsetzung von Bädern.

            Neben dem Fliesenlegen,  beschäftige ich mich auch mit Trockenbau, Malerarbeiten, Tapezierarbeiten, Parkett- und Laminatverlegung, sowie dem Einbau von Holztreppen und Edelholzterrassen.
          </p>
        </div>
      </AboutMe>

    </Section>
  )
}

export default About