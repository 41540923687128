import styled from 'styled-components';
import { colors } from '../../helpers/colors';
import { device } from '../../helpers/global';

export const Section = styled.section`
  height: 100vh;
  max-height: 1200px;
  position: relative;
  background: ${props => `url(${props.bg}) no-repeat center center local`}; 
  background-size: cover;
  overflow: hidden;
  z-index: 10;
  transition: 1s ease-in-out;
`;

export const TextBox = styled.div`
  width: 55rem;
  height: 25rem;
  background: rgba(255, 255, 255, .8);
  border-top-right-radius: .2rem;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 0 4rem 6rem;

  &::after {
    content: '';
    position: absolute;
    top: 4rem;
    right: 0;
    width: 8rem;
    border-bottom: 21rem solid ${colors.theme_secondary};
    border-left: 15rem solid transparent;
  }

  &::before {
    content: '';
    position: absolute;
    top: 9rem;
    right: 17rem;
    width: 4.5rem;
    height: calc(100% - 9rem);
    transform: skew(-36deg);
    background: ${colors.font_accent};
  }
`;

export const TextLine = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  img {
    width: 20px;
    height: 20px;
    margin-right: 1rem;
  }

  p {
    transition: 1s ease-in-out;
    font-size: 2rem;
    font-weight: bold;
    color: ${props => props.curr === 1 ? colors.font_accent : colors.font_main};
  }
`;
