import styled from 'styled-components';
import { device } from '../../../helpers/global';

export const LightboxWrapper = styled.div`
  z-index: 110;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .9);
  display: flex;
  align-items: center;
  padding: 0 120px;
`;

export const ImgBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  @media ${device.md} {
    padding: 5rem;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
`;