import styled from 'styled-components';
import { colors } from '../../../helpers/colors';
import { device } from '../../../helpers/global';

const Btn = styled.button`
  position: absolute;
  top: 50%;
  margin: auto;
  padding: 19px 22px;
  transition: all .2s ease;
  background: transparent;
  color: ${colors.font_secondary};
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ dir }) => dir === 'left' 
    ? `right: 15px;`
    : `left: 15px;`
  }
  z-index: 20;

  @media ${device.md} {
    ${({ dir }) => dir === 'left' 
      ? `right: 75px;`
      : `left: 75px;`
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    ${({ dir }) => dir === 'left' 
      ? `left: 0;`
      : `right: 0;`
    }
    
    display: block;
    border-radius: 28px;
    background: rgba(179, 0, 0, .8);
    width: 56px;
    height: 56px;
    transition: all .3s ease;
  }

  span {
    position: relative;
    font-size: 16px;
    line-height: 18px;
    font-weight: 900;
    letter-spacing: .25em;
    text-transform: uppercase;
    vertical-align: middle;
    display: none;

    @media ${device.md} {
      display: block;
    }
  }

  svg {
    position: relative;
    top: 0;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: ${colors.font_secondary};
    stroke-width: 2;
    ${({ dir }) => dir === 'left' 
      ? `transform: translateX(0); margin-left: 0;`
      : `transform: translateX(0) scaleX(-1); margin-right: 0;`
    }
    transition: all .3s ease;

    @media ${device.md} {
      ${({ dir }) => dir === 'left' 
        ? `transform: translateX(-5px); margin-left: 10px;`
        : `transform: translateX(0) scaleX(-1); margin-right: 10px;`
      }
    }
  }
    
  &:hover {
    &:before {
      width: 100%;
      background: rgba(179, 0, 0, .4);
    }
      
    @media ${device.md} {
      svg {
        ${({ dir }) => dir === 'left' 
          ? `transform: translateX(0);`
          : `transform: translateX(-5px) scaleX(-1)`
        }
      }
    }
    
  }
  &:active {
    transform: scale(.96);
  }
`;

const Button = ({ func, text, direction }) => {
  return (
    <Btn dir={direction} onClick={func}>
      {direction === 'left' && <span>{text}</span>}
      <svg width="13px" height="10px" viewBox="0 0 13 10">
        <path d="M1,5 L11,5"></path>
        <polyline points="8 1 12 5 8 9"></polyline>
      </svg>
      {direction === 'right' && <span>{text}</span>}
    </Btn>
  );
}

export default Button;
