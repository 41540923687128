import { useRef } from 'react';
import { Section, Wrapper, ImgBox } from './Gallery.styles';
import { SectionHeader } from '../SectionHeader/SectionHeader'
import Lightbox from './Lightbox/Lightbox';

const importAll = r => {
  return r.keys().map(r);
}

const images = importAll(require.context('../../images/gallery', false, /\.(png|jpe?g|svg)$/));

const getImgSize = (imgSrc) => {
  const newImg = new Image();

  const dimensions = newImg.onload = function() {
    const height = newImg.height;
    const width = newImg.width;

    return {width, height}
  }

  newImg.src = imgSrc;

  return dimensions();
}

const calculatePadding = (size) => {
  const result = (size.height / size.width) * 100;
  return result
}

const Gallery = () => {
  const childFunc = useRef(null)
  return (
    <Section id="gallery">
      <SectionHeader text={'Galerie'} />
      <Wrapper>
        {images.map((img, i) => {
          const size = parseInt(calculatePadding(getImgSize(img)));
          return (
            <div key={i}>
              <ImgBox size={!isNaN(size) ? size : '205'}>
                <img src={img} alt="example" onClick={() => childFunc.current(img)} loading="lazy" />
              </ImgBox>
            </div>
          )
        })}
        <Lightbox childFunc={childFunc} images={images} />
      </Wrapper>
    </Section>
  );
}

export default Gallery;
