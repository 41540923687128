import { useState, useEffect } from 'react';
import { LightboxWrapper, ImgBox } from './Lightbox.styles';
import { CloseBtn } from './CloseButton';
import Button from './Button';

const Lightbox = ({ images, childFunc }) => {
  const [imageToShow, setImageToShow] = useState('');
  const [lightboxDisplay, setLightboxDisplay] = useState(false);

  useEffect(() => {
    childFunc.current = showImage
  }, [])

  const showImage = (image) => {
    setImageToShow(image);
    setLightboxDisplay(true);
  };

  const hideLightBox = () => {
    setLightboxDisplay(false);
  };

  const showNext = (e) => {
    e.stopPropagation();
    let currentIndex = images.indexOf(imageToShow);
    if (currentIndex >= images.length - 1) {
      setImageToShow(images[0])
    } else {
      setImageToShow(images[currentIndex + 1]);
    }
  };

  const showPrev = (e) => {
    e.stopPropagation();
    let currentIndex = images.indexOf(imageToShow);
    if (currentIndex <= 0) {
      setImageToShow(images[images.length - 1])
    } else {
      setImageToShow(images[currentIndex - 1]);
    }
  };

  const disableWindowScroll = () => {
    document.body.style.overflow = "hidden";
  }
  
  const enableWindowScroll = () => {
    document.body.style.overflow = "visible";
  }
  
  useEffect(() => {
    lightboxDisplay && disableWindowScroll();
    !lightboxDisplay && enableWindowScroll();
  }, [lightboxDisplay]);

  return (
    <>
      {lightboxDisplay ? 
        <LightboxWrapper>
          <CloseBtn callback={hideLightBox} />
          <Button 
            func={showPrev}
            text={'früher'}
            direction={'right'}
          />
          <ImgBox>
            <img src={imageToShow} alt="example lightbox" loading="lazy" />
          </ImgBox>
          <Button 
            func={showNext}
            text={'nächste'}
            direction={'left'}
          />
        </LightboxWrapper>
      : ""}
    </>
  );
}

export default Lightbox;
