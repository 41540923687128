import styled from 'styled-components';
import { colors } from '../../helpers/colors';
import { global, device } from '../../helpers/global';

export const StyledFooter = styled.footer`
  background: ${colors.theme_secondary};
  border-top: 1px solid #1a1a1a;
  padding: ${global.footer_padding};
  color: ${colors.font_secondary};
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  img {
    width: 170px;
    height: auto;
    margin-bottom: 2rem;
    @media ${device.md} {
      margin-bottom: 0;
    }
  }

  @media ${device.md} {
    flex-direction: row;
    font-size: 1.8rem;
  }
`;