import styled from 'styled-components';
import { colors } from '../../helpers/colors';
import { global, device } from '../../helpers/global';

export const Section = styled.section`
  background: ${colors.theme_main};
  padding: ${global.section_padding_mobile};
  color: ${colors.font_secondary};

  @media ${device.lg} {
    padding: ${global.section_padding};
  }
`;

export const Wrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  height: 400px;
  padding: 0 2rem 2rem 0;
  overflow-y: scroll;

  @media (min-width: 414px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.sm} {
    grid-template-columns: repeat(3, 1fr);
    height: 800px;
  }

  @media ${device.md} {
    grid-template-columns: repeat(4, 1fr);
    height: 1000px;
  }

  @media ${device.xl} {
    grid-template-columns: repeat(5, 1fr);
  }

  &::-webkit-scrollbar {
    width: .5rem;
  }
  
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 16px rgba(0, 0, 0, 0.3);
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #b3b3b3;
    outline: 1px solid #1a1a1a;
    border-radius: 5px;
  }
`;

export const ImgBox = styled.li`
    width: 100%;
    max-height: 300px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    background: transparent;
    display: flex;
    justify-content: center;

    @media ${device.lg} {
      padding-top: ${({size}) => `${size - 4}%`}; 
    }

    img {
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      cursor: pointer;    
      background: transparent;

      @media ${device.lg} {
        position: absolute;
      }
    }

    @media ${device.md} {
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        content: '';
        opacity: .3;
        box-sizing: border-box;
        pointer-events: none;
        transition: all 0.5s ease;
        z-index: 2;
      }

      &:hover:before {
        opacity: 0;
        background-color: rgba(0, 0, 0, .9);
      }
    }
`;
