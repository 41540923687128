import styled from 'styled-components';
import { colors } from '../../helpers/colors';
import { global } from '../../helpers/global';

export const Wrapper = styled.div`
  width: 100%;
  height: 10rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 4px 10px -2px #bbb;
  position: fixed;
  top: 0;
  z-index: 100;
  transition: all .4s ease-in-out;

  &.hidden {
    transform: translate3d(0, -10rem, 0);
  }
`;

export const StyledHeader = styled.header`
  width: 100%;
  height: 100%;
  padding: ${global.header_padding};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${colors.font_main};
  font-size: 2.5rem;

  img {
    width: 170px;
    height: auto;
  }
`;
