import { useState, useEffect } from 'react';
import { Section, TextBox, TextLine } from './Cta.styles';
import Scroll from '../utils/Scroll';

import cta1 from '../../images/cta/bausanierung.jpg';
import cta2 from '../../images/cta/bodenverlegung.jpg';
import cta3 from '../../images/cta/holzkonstruktionen.jpg';
import icon from '../../images/house.png';

const Cta = () => {
  const bgs = [cta1, cta2, cta3];
  const texts = ['Bausanierung', 'Bodenverlegung', 'Holzkonstruktionen']

  const [index, setIndex] = useState(0)
  const [currBg, setCurrBg] = useState(cta1);
  const [currText, setCurrText] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(index => (index === 2 ? 0 : index + 1));
    }, 8000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setCurrBg(bgs[index]);
    setCurrText(index)
  }, [index]);

  return (
    <Section bg={currBg} id="home">
      <TextBox>
        {texts.map((text, i) => (
          <TextLine curr={currText === i ? 1 : 0} key={i}>
            <img src={icon} alt="icon" />
            <p>{text}</p>
          </TextLine>
        ))}
      </TextBox>

      <Scroll link={'#about'} />
    </Section>
  );
}

export default Cta;
