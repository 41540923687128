import styled from 'styled-components';

const StyledContainer = styled.div`
  margin: 0 auto;
  max-width: 1920px;
  width: 100%;
  height: 100%;
`;

export const Container = ({children}) => {
  return (
    <StyledContainer>{children}</StyledContainer>
  )
}
