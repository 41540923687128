import styled from 'styled-components';
import { colors } from '../../../helpers/colors';
import { items } from '../../../helpers/navLinks';

const Ul = styled.ul`
  list-style-type: none;
  display: flex;
  align-items: center;
`;

const Li = styled.li`
  &:not(:first-child) {
    margin-left: 5rem;
  }

  a {
    font-size: 2rem;
    font-weight: 500;
    transition: .2s ease-in;
    &:hover, &:focus {
      color: ${colors.font_accent};
    }
  }
`;

const Nav = () => {
  return (
    <nav>
      <Ul>
        {items.map((item, index) => (
          <Li key={index}>
            <a href={item.link}>{item.title}</a>
          </Li>
        ))}
      </Ul>
    </nav>
  );
}

export default Nav;
