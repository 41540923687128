import styled from 'styled-components';
import { colors } from '../../../helpers/colors';

export const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 10rem);
  background: ${colors.theme_main};
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 10rem;
  right: 0;

  &.opened {
    transform: translateX(100%);
    transition: transform .3s linear;
  }
  
  &.closed {
    transform: translateX(0%);
    transition: transform .3s linear;
  }
`;

export const Ul = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Li = styled.li`
  &:not(:first-child) {
    margin-top: 5rem;
  }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: .6rem;
    color: black};
    text-decoration: none;
    font-weight: 500;
    transition: .2s ease-in;

    &:hover, &:focus {
      color: ${colors.font_accent};
    }
  }
`;