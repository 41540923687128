import styled from 'styled-components';
import { colors } from '../../helpers/colors';
import { device } from '../../helpers/global';

const ScrollBox = styled.div`
  width: 2.5rem;
  height: 4rem;
  border: 2px solid ${colors.font_secondary};
  border-radius: 5rem;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  @media ${device.md} {
    display: block;
  }

  &::before, &::after {
    content: '';
    display: block;
    position: absolute;
    top: 20%;
    left: 50%;
    border: .2rem solid ${colors.font_secondary};
    height: .4rem;
    width: .4rem;
    cursor: pointer;
    transform: translate(-50%, -100%) rotate(45deg);
    border-top: transparent;
    border-left: transparent;
    animation: scroll-down 1.2s ease-in-out infinite;
  }

  &::after {
    top: 20%;
    animation-delay: .4s;
  }

  @keyframes scroll-down {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      top: 90%;
    }
  }
`;

const Scroll = ({ link }) => {
  return (
    <a href={link}>
      <ScrollBox />
    </a>
  );
}

export default Scroll;
