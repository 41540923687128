import { createGlobalStyle } from 'styled-components';

export const breakpoints = {
  xs: '360px',
  sm: '768px',
  md: '1024px',
  lg: '1224px',
  xl: '1440px',
  full: '1920px'
};

export const device = {
  xs: `(min-width: ${breakpoints.xs})`,
  sm: `(min-width: ${breakpoints.sm})`,
  md: `(min-width: ${breakpoints.md})`,
  lg: `(min-width: ${breakpoints.lg})`,
  xl: `(min-width: ${breakpoints.xl})`,
  full: `(min-width: ${breakpoints.full})`
};

export const global = {
  header_padding: '2rem 4rem',
  footer_padding: '6rem 4rem',
  section_padding: '18rem 4rem',
  section_padding_mobile: '8rem 2rem',
  section_header_size: '11rem',
  section_header_mobile_size: '6rem',
};

export const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
  }
  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Noto Sans', 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #ccc;
    font-weight: 400;
  }
`;