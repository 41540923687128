import styled from 'styled-components';
import { colors } from '../../../helpers/colors';
import { device } from '../../../helpers/global';

const CloseButton = styled.button`
  border: none;
  background: transparent;
  outline: none;
  position: absolute;
  top: 2rem;
  right: 2rem;
  padding: 0;
  cursor: pointer;
  z-index: 100;
  width: 4rem;
  height: 5rem;

  @media ${device.md} {
    top: 10rem;
    right: 10rem;
    width: 8rem;
  }
  
  &:hover, &:focus {
    .sign {
      &:before, &:after {
        transform: rotate(0);
      }
      &:before {
        top: 0;
      }
      &:after {
        bottom: 0;
      }
    }

    .sign > .label {
      opacity: 1;
    }
  } 

  .sign {
    width: inherit;
    text-align: center;

    &:before, &:after {
      position: absolute;
      content: '';
      height: 2px;
      width: inherit;
      background: ${colors.theme_main};
      left: 0;
      transition: all .3s ease-in;
    }

    &:before {
      top: 50%; 
      transform: rotate(45deg) translateX(-1px);  
    }
    
    &:after {  
      bottom: 50%;
      transform: rotate(-45deg);  
    }
  }

  .label {
    font-size: 1rem; 
    line-height: 3rem;
    text-transform: uppercase;
    color: ${colors.font_secondary};;
    transition: all .3s ease-in;
    opacity: 0;
    cursor: pointer;
  }
`;

export const CloseBtn = ({ callback }) => {
  return (
    <CloseButton onClick={callback}>
      <div className="sign">
        <span className="label">schließen</span>
      </div>
    </CloseButton>
  );
}