import styled from 'styled-components';
import { colors } from '../../helpers/colors';
import { global, device } from '../../helpers/global';

import React from 'react';

const Header = styled.header`
  text-align: center;
  width: 100%;
  margin-bottom: ${props => 
    props.marginBottom ? props.marginBottom : '12rem'};
  color: ${props => 
    props.color === 'white' ? colors.font_secondary : colors.font_main};

  h2 {
    font-size: ${global.section_header_mobile_size};
    font-weight: 500;
    @media ${device.lg} {
      font-size: ${global.section_header_size};
    }
  }
`;

export const SectionHeader = ({ color, text, marginBottom }) => {
  return (
    <Header marginBottom={marginBottom} color={color}>
      <h2>{text}</h2>
    </Header>
  );
}
