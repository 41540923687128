import styled from 'styled-components';
import { device } from '../../helpers/global';

const StyledMap = styled.iframe`
  width: 100%;
  height: 300px;
  margin-top: 2rem;

  @media ${device.sm} {
    width: 600px;
    height: 400px;
  }

  @media ${device.full} {
    width: ${props => props.width};
    height: ${props => props.height};
    margin-top: 0;
  }
`;

const Map = ({ 
  width,
  height,
}) => {

  return (
    <StyledMap width={width} height={height} loading={"lazy"} allowFullScreen src={"https://www.google.com/maps/embed/v1/view?zoom=17&center=50.5048%2C8.0653&key=AIzaSyB_p8yh7capqIcjDYwuWV1L9TOM0iQm_bY"}></StyledMap>
  );
}

export default Map;