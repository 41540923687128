import styled from 'styled-components';
import { colors } from '../../helpers/colors';
import { global, device } from '../../helpers/global';

export const Section = styled.section`
  background: ${colors.theme_main};
  padding: ${global.section_padding_mobile};
  display: flex;
  justify-content: center;
  position: relative;
  overflow-x: clip;
  overflow-y: visible;

  ${'' /* flex-direction: column;
  align-items: center; */}
  ${'' /* @media ${device.md} {
    flex-direction: row;
  } */}

  @media ${device.lg} {
    padding: ${global.section_padding};
  }

  @media ${device.lg} {
    &:before,
    &:after {
      content: "";
      position: absolute;
      border-radius: 50%;
    }
  }

  &:before {
    bottom: -21rem;
    left: -23rem;
    width: 55rem;
    height: 55rem;
    border: 12rem solid ${colors.font_accent};
    opacity: .3;
  }

  &:after {
    top: -20rem;
    right: -25rem;
    width: 30rem;
    height: 30rem;
    border: 15rem solid ${colors.font_main};
    background: ${colors.font_main};
    opacity: .6;
  }
`;

export const AboutMe = styled.div`
  width: 100%;
  height: 100%;
  min-height: 40rem;
  z-index: 10;

  @media ${device.md} {
    width: 80%;
    padding: 6rem 8rem;
    ${'' /* width: 60%; */}
    ${'' /* border-right: 1px solid #ccc; */}
    ${'' /* padding: 6rem 8rem 6rem 2rem; */}
    ${'' /* margin-right: 8rem; */}
  }

  p {
    font-size: 1.6rem;
    text-align: center;

    @media ${device.md} {
      padding-left: 4rem;
      font-size: 1.8rem;
      text-align: left;
    }

    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
`;

// export const PictureBox = styled.div`
//   width: 100%;
//   height: 100%;
//   padding: 6rem 0;
//   z-index: 10;
//   display: flex;
//   justify-content: center;

//   img {
//     max-width: 100%;
//     height: auto;
//   }

//   @media ${device.md} {
//     width: 30%;
//   }
// `;