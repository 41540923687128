import { items } from '../../../helpers/navLinks';
import { StyledMenu, Ul, Li } from './Menu.styles';

const Menu = ({ open, setOpen }) => {
  return (
    <StyledMenu className={!open ? 'opened' : 'closed'}>
      <Ul>
        {items.map((item, index) => (
          <Li key={index}>
            <a onClick={() => setOpen(false)} href={item.link}>{item.title}</a>
          </Li>
        ))}
      </Ul>
    </StyledMenu>
  )
}
export default Menu;