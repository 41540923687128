import styled from 'styled-components';
import { colors } from '../../helpers/colors';
import { global, device } from '../../helpers/global';

export const Section = styled.section`
  background: ${colors.theme_secondary};
  padding: ${global.section_padding_mobile};
  color: ${colors.font_secondary};
  position: relative;
  overflow: hidden;

  @media ${device.lg} {
    padding: ${global.section_padding};
  }

  @media ${device.xl} {
    &:before,
    &:after {
      content: "";
      position: absolute;
      border-radius: 50%;
    }
  }

  &:before {
    bottom: 15rem;
    left: -15rem;
    width: 65rem;
    height: 65rem;
    background: ${colors.font_secondary};
    opacity: .3;
  }

  &:after {
    top: 20rem;
    right: -35rem;
    width: 50rem;
    height: 50rem;
    border: 12rem solid ${colors.font_accent};
    opacity: .7;
  }
`;

export const Header = styled.header`
  text-align: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${device.xl} {
    flex-direction: row;
  }
`;

export const ContactData = styled.div`
  width: 100;
  padding: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  @media ${device.xl} {
    width: 50%;
    padding: 10rem;
    border-right: 1px solid #ccc;
    text-align: left;
  }

  @media ${device.full} {
    width: 60%;
    padding: 20rem;
    flex-direction: row;
    
  }

  h3 {
    font-size: 1.6rem;
    margin-bottom: 4rem;
    font-weight: 500;
  }

  .column {
    &--first {
      width: 100%;
      margin-bottom: 2rem;

      @media ${device.full} {
        width: 40%;
        margin-bottom: 0;
      }
    }
    &--second {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media ${device.xl} {
        align-items: flex-end;
      }
      
      @media ${device.full} {
        width: 50%;
        justify-content: flex-end;
      }
    }
  }
`;

export const AddressLine = styled.p`
  font-size: 2rem;
  line-height: 4rem;
  font-weight: 500;

  @media ${device.xl} {
    font-size: 2.4rem;
  }
`;

export const ContactLine = styled.a`
  color: ${colors.font_secondary};
  font-size: 2rem;
  line-height: 4rem;
  font-weight: 500;
  display: block;
  position: relative;

  @media ${device.xl} {
    font-size: 2.4rem;
  }

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 0.1rem;
    background: ${colors.font_secondary};
    opacity: .6;
    position: absolute;
    bottom: 4px;
    transition: opacity .3s ease;
  }

  &:hover, &:focus {
    &:after {
      opacity: 1;
    }
  }
`;

export const MapContainer = styled.div`
  z-index: 10;

  @media ${device.xl} {
    width: 40%;
    padding: 6rem;
    padding-left: 8rem;
  }
`;