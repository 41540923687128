import { useState, useRef, useEffect } from 'react';
import { StyledHeader, Wrapper } from './Header.styles';
import { Container } from '../utils/Container';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { breakpoints } from '../../helpers/global';

import Burger from './Burger/Burger';
import Menu from './Menu/Menu';
import Nav from './Nav/Nav';

import logo from '../../images/logo.png';


const Header = () => {
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isOnTop, setIsOnTop] = useState(true);
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const node = useRef(); 
  useOnClickOutside(node, () => setOpen(false));
  const { width } = useWindowDimensions();

  useEffect(() => {
    width <= parseInt(breakpoints.lg) ? setIsMobile(true) : setIsMobile(false);
  }, [width]);

  const controlNavbar = () => {
    if (typeof window !== 'undefined') { 
      window.scrollY > lastScrollY 
        ? setShow(false)
        : setShow(true); 
      setLastScrollY(window.scrollY); 
    }
    window.scrollY !== 0 ? setIsOnTop(false) : setIsOnTop(true)
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);

      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);

  const disableWindowScroll = () => {
    document.body.style.overflow = "hidden";
  }
  
  const enableWindowScroll = () => {
    document.body.style.overflow = "visible";
  }
  
  useEffect(() => {
    open && disableWindowScroll();
    !open && enableWindowScroll();
  }, [open]);

  return (
    <Wrapper className={`${!show && 'hidden'} ${isOnTop && 'transparent'}`}>
      <Container>
        <StyledHeader>
          <img src={logo} alt="logo" />
          {!isMobile && <Nav />}
          {isMobile && (
            <div ref={node}>
              <Burger open={open} setOpen={setOpen} />
              <Menu open={open} setOpen={setOpen} />
            </div>
          )}
        </StyledHeader>
      </Container>
    </Wrapper>
  );
}

export default Header;
