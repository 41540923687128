import { Container } from './components/utils/Container';
import { GlobalStyles } from './helpers/global';

import Header from './components/Header/Header';
import Cta from './components/CTA/Cta';
import About from './components/About/About';
import Gallery from './components/Gallery/Gallery';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className="App">
      <>
        <GlobalStyles />
        <Header />
        <Container>
          <Cta />
          <About />
          <Gallery />
          <Contact />
          <Footer />
        </Container>
      </>
    </div>
  );
}

export default App;
