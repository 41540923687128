import { 
  Section, 
  Wrapper,
  ContactData,
  MapContainer,
  AddressLine,
  ContactLine 
} from './Contact.styles';
import { SectionHeader } from '../SectionHeader/SectionHeader'

import Map from './Map.js';

const Contact = () => {
  return (
    <Section id="contact">
      <SectionHeader text={'Kontakt'} color={'white'} />
      <Wrapper>
        <ContactData>
          <div className="column--first">
            <h3>Kontaktdaten:</h3>
            <address>
              <AddressLine>Piotr Szewczyk</AddressLine>
              <AddressLine>Untere Gadelheimer Mühle 1A</AddressLine>
              <AddressLine>65627 Elbtal</AddressLine>
            </address>
          </div>
          <div className="column--second">
            <ContactLine href="tel:+49 1517 1996756" >
              +49 1517 1996756
            </ContactLine>
            <ContactLine href="mailto:szewczykpiotr684@gmail.com" target="_blank" rel="noopener">szewczykpiotr684@gmail.com</ContactLine>
          </div>
        </ContactData>
        <MapContainer>
          <Map width={'600px'} height={"450px"} />
        </MapContainer>
      </Wrapper>
    </Section>
  );
}

export default Contact;
