export const items = [
  {
    title: 'Zuhause',
    link: '#home'
  },
  {
    title: 'Über mich',
    link: '#about'
  },
  {
    title: 'Galerie',
    link: '#gallery'
  },
  {
    title: 'Kontakt',
    link: '#contact'
  },
];